<template>
  <div v-if="totalPage !== 0" class="button-container">
    <div v-show="totalPage > 1 && !loading && showBtnAngleLeft" class="btn-angle-left" @click="slideLeft">
      <FeatherIcon type="chevron-left" size="16" />
    </div>
    <a-row class="mobile-row-social">
      <div class="row-overview">
        <a-col v-for="(item, index) in overviewData.list" :key="index">
          <a-popover class="comparison-popover" placement="bottomLeft" trigger="hover">
            <template #content>
              <div class="pop-data-wrapper">
                <div class="title">{{ item.campaign }}</div>
                <div class="value-wrapper">
                  <div class="value">{{ item.formatted }}</div>
                </div>
              </div>
            </template>
            <FacebookOverviewButton
              class="cursor-pointer"
              :selected="mode"
              :disable-tooltip="true"
              :title="item.campaign"
              :loading="loading"
              :value="item.value"
              :percent-change="item.percentChange"
            />
          </a-popover>
        </a-col>
      </div>
    </a-row>
    <div v-show="totalPage > 1 && !loading && showBtnAngleRight" class="btn-angle-right" @click="slideRight">
      <FeatherIcon type="chevron-right" size="16" />
    </div>
  </div>
</template>
<script>
import numeral from 'numeral';
import FacebookOverviewButton from '@/components/Button/FacebookOverviewButton';
import { ref, toRefs, reactive, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import EngagementDetail from '@/components/Detail/EngagementDetail';

export const ComparisonOverview = {
  name: 'ComparisonOverview',
  components: {
    FacebookOverviewButton,
    EngagementDetail,
  },
  props: {
    mode: String,
    filter: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { mode, filter } = toRefs(props);
    const isMobileScreen = ref(false);
    const isSmallScreen = ref(false);
    const brand = computed(() => store.state.account.brand);
    const campaignMenuData = computed(() => store.state.account.campaignMenuData);
    let loading = ref(true);
    let error = ref(false);
    let showBtnAngleLeft = ref(false);
    let showBtnAngleRight = ref(true);
    let windowWidth = ref(window.innerWidth);
    let overviewModeData = reactive({
      engagement: [],
      mentioned: [],
      view: [],
    });
    const setMode = computed(() => {
      if (mode.value === 'mention') {
        return 'mentioned';
      } else {
        return mode.value;
      }
    });
    let overviewData = ref([]);
    let campaign = ref([]);
    let page = ref(1);
    let totalPage = ref(0);

    const select = (e) => {
      emit('typeChange', e);
    };

    const loadData = async (apiFilter) => {
      if (apiFilter) {
        const result = await api
          .getCampaignComparsionOverview(apiFilter, brand.value, campaignMenuData.value.categoryLevel)
          .catch(() => {
            error.value = true;
          });
        if (result.message && result.message.data) {
          const data = result.message.data;
          const keyNames = Object.keys(data);
          for (let item of campaignMenuData.value.categoryList) {
            if (item.visible) {
              for (let name of keyNames) {
                if (name === item.categoryName) {
                  const obj = {
                    name: item.categoryName,
                    engagement: data[item.categoryName].engagement,
                    mention: data[item.categoryName].mention,
                    view: data[item.categoryName].view,
                  };
                  campaign.value.push(obj);
                }
              }
            }
          }
        }
      }
    };

    const initDesktop = () => {
      let pageIndex = 1;
      let engagement = [];
      let mention = [];
      let view = [];
      for (let c in campaign.value) {
        engagement.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].engagement.value,
          formatted: numeral(campaign.value[c].engagement.value).format('0,0'),
          percentChange: campaign.value[c].engagement.percentChange,
        });
        mention.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].mention.value,
          formatted: numeral(campaign.value[c].mention.value).format('0,0'),
          percentChange: campaign.value[c].mention.percentChange,
        });
        view.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].view.value,
          formatted: numeral(campaign.value[c].view.value).format('0,0'),
          percentChange: campaign.value[c].view.percentChange,
        });
        if ((parseInt(c) + 1) % 6 === 0 || parseInt(c) + 1 === campaign.value.length) {
          overviewModeData.engagement.push({
            page: pageIndex,
            list: engagement,
          });
          overviewModeData.mentioned.push({
            page: pageIndex,
            list: mention,
          });
          overviewModeData.view.push({
            page: pageIndex,
            list: view,
          });

          if (parseInt(c) + 1 === campaign.value.length) {
            totalPage.value = pageIndex;
          } else {
            pageIndex++;

            engagement = [];
            mention = [];
            view = [];
          }
        }
      }
      if (page.value === 1) {
        overviewData.value = overviewModeData[setMode.value][0];
      } else {
        overviewData.value = overviewModeData[setMode.value].find((data) => data.page === page.value);
      }
    };

    const initSmall = () => {
      let pageIndex = 1;
      let engagement = [];
      let mention = [];
      let view = [];
      for (let c in campaign.value) {
        engagement.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].engagement.value,
          formatted: numeral(campaign.value[c].engagement.value).format('0,0'),
          percentChange: campaign.value[c].engagement.percentChange,
        });
        mention.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].mention.value,
          formatted: numeral(campaign.value[c].mention.value).format('0,0'),
          percentChange: campaign.value[c].mention.percentChange,
        });
        view.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].view.value,
          formatted: numeral(campaign.value[c].view.value).format('0,0'),
          percentChange: campaign.value[c].view.percentChange,
        });
        if ((parseInt(c) + 1) % 4 === 0 || parseInt(c) + 1 === campaign.value.length) {
          overviewModeData.engagement.push({
            page: pageIndex,
            list: engagement,
          });
          overviewModeData.mentioned.push({
            page: pageIndex,
            list: mention,
          });
          overviewModeData.view.push({
            page: pageIndex,
            list: view,
          });

          if (parseInt(c) + 1 === campaign.value.length) {
            totalPage.value = pageIndex;
          } else {
            pageIndex++;

            engagement = [];
            mention = [];
            view = [];
          }
        }
      }
      if (page.value === 1) {
        overviewData.value = overviewModeData[setMode.value][0];
      } else {
        overviewData.value = overviewModeData[setMode.value].find((data) => data.page === page.value);
      }
    };

    const initMobile = () => {
      let pageIndex = 1;
      let engagement = [];
      let mention = [];
      let view = [];
      for (let c in campaign.value) {
        engagement.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].engagement.value,
          formatted: numeral(campaign.value[c].engagement.value).format('0,0'),
          percentChange: campaign.value[c].engagement.percentChange,
        });
        mention.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].mention.value,
          formatted: numeral(campaign.value[c].mention.value).format('0,0'),
          percentChange: campaign.value[c].mention.percentChange,
        });
        view.push({
          campaign: campaign.value[c].name,
          value: campaign.value[c].view.value,
          formatted: numeral(campaign.value[c].view.value).format('0,0'),
          percentChange: campaign.value[c].view.percentChange,
        });
        if ((parseInt(c) + 1) % 3 === 0 || parseInt(c) + 1 === campaign.value.length) {
          overviewModeData.engagement.push({
            page: pageIndex,
            list: engagement,
          });
          overviewModeData.mentioned.push({
            page: pageIndex,
            list: mention,
          });
          overviewModeData.view.push({
            page: pageIndex,
            list: view,
          });

          if (parseInt(c) + 1 === campaign.value.length) {
            totalPage.value = pageIndex;
          } else {
            pageIndex++;

            engagement = [];
            mention = [];
            view = [];
          }
        }
      }
      if (page.value === 1) {
        overviewData.value = overviewModeData[setMode.value][0];
      } else {
        overviewData.value = overviewModeData[setMode.value].find((data) => data.page === page.value);
      }
    };

    const init = async () => {
      loading.value = true;
      error.value = false;
      checkWidthVal();
      overviewModeData.engagement = [];
      overviewModeData.mentioned = [];
      overviewModeData.view = [];
      await loadData(filter.value);
      if (campaign.value) {
        if (isMobileScreen.value) {
          initMobile();
        } else if (isSmallScreen.value) {
          initSmall();
        } else {
          initDesktop();
        }
      }
      loading.value = false;
    };

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
      if (windowWidth.value <= 600 && totalPage.value.length > 1) {
        page.value = 1;
        overviewData.value = overviewModeData[setMode.value].find((data) => data.page === page.value);
        showBtnAngleLeft.value = false;
        showBtnAngleRight.value = true;
      }
    };

    const slideLeft = () => {
      page.value -= 1;
      if (page.value !== 1) {
        overviewData.value = overviewModeData[setMode.value].find((data) => data.page === page.value);
        showBtnAngleLeft.value = true; // true
        showBtnAngleRight.value = true;
      } else {
        page.value = 1;
        overviewData.value = overviewModeData[setMode.value].find((data) => data.page === page.value);
        showBtnAngleLeft.value = false; // true
        showBtnAngleRight.value = true; // false
      }
    };

    const slideRight = () => {
      page.value += 1;
      overviewData.value = overviewModeData[setMode.value].find((data) => data.page === page.value);
      if (page.value < totalPage.value) {
        showBtnAngleLeft.value = true;
        showBtnAngleRight.value = true;
      } else if (page.value === totalPage.value) {
        showBtnAngleLeft.value = true; // false
        showBtnAngleRight.value = false; // true
      }
    };

    const checkWidthVal = () => {
      page.value = 1;
      showBtnAngleLeft.value = false;
      showBtnAngleRight.value = true;
      if (windowWidth.value >= 1200) {
        isMobileScreen.value = false;
        isSmallScreen.value = false;
      } else if (windowWidth.value >= 768) {
        isMobileScreen.value = false;
        isSmallScreen.value = true;
      } else {
        isMobileScreen.value = true;
        isSmallScreen.value = false;
      }
    };

    watch(
      () => filter.value,
      () => {
        overviewModeData.engagement = [];
        overviewModeData.mentioned = [];
        overviewModeData.view = [];
        overviewData.value = [];
        campaign.value = [];
        init();
      }
    );
    watch(
      () => mode.value,
      () => {
        let modeValue = '';
        if (mode.value === 'mention') {
          modeValue = 'mentioned';
        } else {
          modeValue = mode.value;
        }
        overviewData.value = overviewModeData[modeValue][0];
      }
    );
    watch(
      () => windowWidth.value,
      () => {
        checkWidthVal();
        overviewModeData.engagement = [];
        overviewModeData.mentioned = [];
        overviewModeData.view = [];
        if (campaign.value) {
          if (isMobileScreen.value) {
            initMobile();
          } else if (isSmallScreen.value) {
            initSmall();
          } else {
            initDesktop();
          }
        }
      }
    );

    onMounted(() => {
      window.addEventListener('resize', onWidthChange);
      onWidthChange();
    });
    return {
      // breakpoints,
      loading,
      overviewModeData,
      overviewData,
      slideLeft,
      slideRight,
      showBtnAngleLeft,
      showBtnAngleRight,
      isMobileScreen,
      isSmallScreen,
      select,
      windowWidth,
      page,
      totalPage,
      campaign,
    };
  },
};
export default ComparisonOverview;
</script>
<style lang="scss" scoped>
.mobile-row-social {
  width: 100%;
  height: 100%;
  margin-left: 39px;
  overflow-y: scroll;
}
.mobile-row-social::-webkit-scrollbar {
  display: none;
}
.row-overview {
  width: 100%;
  display: flex;
  column-gap: 8px;
  padding: 0 12px;
}
.btn-angle-left,
.btn-angle-right {
  display: block;
  cursor: pointer;
  width: 24px;
  height: 24px;
  // left: 0px;
  // top: 111px;
  background: #e0eaff;
  box-shadow: 0px 0px 10px rgba(51, 113, 255, 0.2);
  border-radius: 24px;
  padding: 4px;
  z-index: 1;
}
.btn-angle-right {
  margin-right: 10px;
  margin-left: 5px;
}

.btn-angle-left {
  position: absolute;
  margin-right: 5px;
  margin-left: 10px;
}
.button-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.pop-data-wrapper {
  .title {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #9299b8;
    align-items: center;
    display: flex;
    text-transform: capitalize;
  }

  .value {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #272b41;
    margin-bottom: 10px;
  }
}

.row-social {
  // justify-content: space-between;
  align-items: center;
}
.mr-icon {
  margin-right: 5px;
}

@media only screen and (max-width: 600px) {
  .mobile-row-social {
    margin-left: 25px;
  }
  .button-container {
    display: flex;
    align-items: center;
  }
  .btn-angle-left,
  .btn-angle-right {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
    // left: 0px;
    // top: 111px;
    background: #e0eaff;
    box-shadow: 0px 0px 10px rgba(51, 113, 255, 0.2);
    border-radius: 24px;
    padding: 4px;
    z-index: 1;
  }

  .btn-angle-right {
    margin-right: 0;
    margin-left: 0;
  }

  .btn-angle-left {
    margin-left: 0;
    margin-right: 0;
  }

  .mobile-row-social {
    align-items: center;
    margin-left: 19px;
    // flex-flow: unset;
  }

  .row-overview {
    padding: 0;
  }
}
</style>
<style scoped>
:deep(.title-overview div) {
  width: 127px;
  font-family: 'DM Sans', 'IBM Plex Sans Thai', sans-serif;
}

.pop-data-wrapper .title {
  font-family: 'DM Sans', 'IBM Plex Sans Thai', sans-serif;
}
:deep(.stat-button) {
  justify-content: center;
}
:deep(.row-overview > .ant-col) {
  max-width: 16%;
  flex: 1 0 16%;
  flex-basis: calc(16% - 60px);
}
@media only screen and (max-width: 1100px) {
  :deep(.title-overview div) {
    max-width: 97px;
    width: 100%;
  }
  :deep(.value) {
    min-width: 63px;
    font-size: 20px;
  }
  :deep(.percent-content) {
    font-size: 12px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1199px) {
  :deep(.row-overview > .ant-col) {
    max-width: 25%;
    flex: 1 0 25%;
    flex-basis: calc(25% - 60px);
  }
}
@media only screen and (max-width: 768px) {
  :deep(.row-overview > .ant-col) {
    /* max-width: 33%;
    flex: 1 0 33%;
    flex-basis: calc(33% - 60px); */
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
}
</style>
