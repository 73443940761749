<template>
  <a-row class="MertricAndSummary" :gutter="25">
    <a-col class="mh-100 pd-b-25" :xxl="16" :xl="14" :lg="12" :xs="24">
      <a-card v-resize="hideOverflowTitle" :title="`By Metric: ${mode}`" class="metric-card">
        <ComparisonByMetric :mode="mode" :filterResult="filterResult"></ComparisonByMetric>
      </a-card>
    </a-col>
    <a-col class="mh-100 pd-b-25" :xxl="8" :xl="10" :lg="12" :xs="24">
      <a-card title="By Summary" class="summary-card">
        <template #extra>
          <div class="category-top-right-action d-flex">
            <slot name="category"> </slot>
            <a-button
              :type="tabActive === 'pie' && !noData ? 'primary' : 'ghost'"
              class="circular"
              :class="{ 'button-primary-light': tabActive === 'pie' && !noData }"
              style="width: 42px"
              @click="handleActiveChange('pie')"
              :disabled="noData"
            >
              <FeatherIcon type="pie-chart" size="18" />
            </a-button>
            <a-button
              :type="tabActive === 'progressbar' && !noData ? 'primary' : 'ghost'"
              class="circular"
              style="width: 42px"
              :class="{ 'button-primary-light': tabActive === 'progressbar' && !noData }"
              @click="handleActiveChange('progressbar')"
              :disabled="noData"
            >
              <FeatherIcon type="list" size="18" />
            </a-button>
          </div>
        </template>
        <ComparisonSummary 
          :tabActive="tabActive"
          :mode="mode"
          :filter="filterResult"  
          @noResultChange="noResultChange"
        >
        </ComparisonSummary>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { ref } from 'vue';
import ComparisonByMetric from './ComparisonByMetric.vue';
import ComparisonSummary from './ComparisonSummary.vue';
export default {
  name: 'MetricAndSummary',
  components: {
    ComparisonByMetric,
    ComparisonSummary,
  },
  props: {
    mode: String,
    filterResult: Object,
  },
  setup() {
    const tabActive = ref('progressbar');
    const noData = ref(true)
    const handleActiveChange = (value) => {
      tabActive.value = value;
    };

    const noResultChange = (noDataRes) => {
      noData.value = noDataRes
    }

    return {
      tabActive,
      handleActiveChange,
      noData,
      noResultChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../../config/theme/colors.json';
.mh-100 {
  min-height: 100%;
}
.pd-b-25 {
  padding-bottom: 25px;
}
.MertricAndSummary {
  .metric-card {
    height: 100%;
  }
  .summary-card {
    height: 100%;
    .category-top-right-action {
      align-items: center;
      .button-primary-light {
        background: #2c99ff15;
        border-width: 0px;
        color: $primary-color;
        &:focus {
          background: #2c99ff15;
          border-width: 0px;
          color: $primary-color;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.MertricAndSummary {
  .metric-card {
    .ant-card-head .ant-card-head-wrapper {
      flex-flow: row;
      align-items: center;
      .ant-card-head-title {
        text-transform: capitalize;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .ant-card-head-wrapper {
      flex-flow: unset;
    }
  }
}
</style>
<style scoped>
:deep(.ant-card-extra) {
  padding: 0 !important;
}
:deep(.metric-card .ant-card-body) {
  padding: 0 !important;
}
</style>
