<template>
  <div class="channel-table-wrapper">
    <div v-if="loading" class="loading-wrapper">
      <a-skeleton :loading="loading" active />
    </div>
    <div v-else-if="!loading && metricData.length <= 0" class="load-wrapper">
      <NoDataFound></NoDataFound>
    </div>
    <div v-else class="channel-table">
      <a-table
        :data-source="metricData"
        :columns="isMobileScreen ? metricMobileColumns : metricColumns"
        :pagination="false"
        bordered
        size="middle"
        :scroll="{ x: mode === 'engagement' ? 'calc(500px + 50%)' : '', y: 458 }"
      >
        <template #customNameTitle="{ title }">
          <span class="campaign-title">{{ title }}</span>
        </template>
        <template v-if="!isMobileScreen" #customModeTitle="">
          <span class="mode-title">{{ mode }}</span>
        </template>
        <template #name="{ text, index }">
          <a-tooltip :title="text" placement="bottom">
            <div class="badge-wrapper">
              <a-badge :color="getColor(text, index)" />
              <span class="category-text"> {{ text }} </span>
            </div>
          </a-tooltip>
        </template>
        <template #customTotalEngagementTitle="">
          <div class="comparison-wrapper">
            <span class="category-text"> Total </span>
          </div>
        </template>
        <template #customReactionTitle="">
          <div class="comparison-wrapper">
            <FeatherIcon :type="'thumbs-up'" size="12" />
            <span class="category-text"> Reaction </span>
          </div>
        </template>
        <template #customCommentTitle="">
          <div class="comparison-wrapper">
            <FeatherIcon :type="'message-square'" size="12" />
            <span class="category-text"> Comment </span>
          </div>
        </template>
        <template #customShareTitle="">
          <div class="comparison-wrapper">
            <FeatherIcon :type="'share-2'" size="12" />
            <span class="category-text"> Share </span>
          </div>
        </template>
        <template #customModeDataTitle="">
          <div class="comparison-wrapper">
            <span class="category-text">Engagement<br />/Mentioned</span>
          </div>
        </template>
        <template #customTotalMetionTitle="">
          <div class="comparison-wrapper">
            <span class="category-text"> Total Mentioned </span>
          </div>
        </template>
        <template #customFrequencyTitle="">
          <div class="comparison-wrapper">
            <span class="category-text"> Frequency <wbr />(mentions/day) </span>
          </div>
        </template>
        <template #customVideoViewTitle="">
          <div class="comparison-wrapper">
            <span class="category-text"> Video View </span>
          </div>
        </template>
        <template #customPageViewTitle="">
          <div class="comparison-wrapper">
            <span class="category-text"> Page View </span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed, reactive, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
import NoDataFound from '@/components/Error/NoDataFound.vue';

export default {
  name: 'ComparisonByMetric',

  props: {
    mode: String,
    filterResult: Object,
  },

  components: {
    NoDataFound,
  },

  setup(props) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);
    const { mode, filterResult } = toRefs(props);
    let loading = ref(false);
    const metricColumnsByMode = reactive({
      engagement: [
        {
          title: 'Campaign',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 150,
          ellipsis: true,
          slots: { title: 'customNameTitle', customRender: 'name' },
        },
        {
          dataIndex: 'mode',
          key: 'mode',
          align: 'center',
          width: 400,
          slots: { title: 'customModeTitle', customRender: 'mode' },
          children: [
            {
              dataIndex: 'engagement',
              key: 'engagement',
              align: 'left',
              width: 100,
              slots: { title: 'customTotalEngagementTitle' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
            {
              dataIndex: 'reactions',
              key: 'reactions',
              align: 'left',
              width: 100,
              slots: { title: 'customReactionTitle' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
            {
              dataIndex: 'comment',
              key: 'comment',
              align: 'left',
              width: 100,
              slots: { title: 'customCommentTitle' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
            {
              dataIndex: 'share',
              key: 'share',
              align: 'left',
              width: 100,
              slots: { title: 'customShareTitle' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
            {
              dataIndex: 'engagementPerMention',
              key: 'engagementPerMention',
              align: 'left',
              width: 100,
              slots: { title: 'customModeDataTitle' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
          ],
        },
      ],
      mention: [
        {
          title: 'Campaign',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          slots: { title: 'customNameTitle', customRender: 'name' },
        },
        {
          dataIndex: 'mode',
          key: 'mode',
          align: 'center',
          width: 300,
          slots: { title: 'customModeTitle', customRender: 'mode' },
          children: [
            {
              dataIndex: 'mentioned',
              key: 'mentioned',
              align: 'left',
              width: 150,
              slots: { title: 'customTotalMetionTitle', customRender: 'mentioned' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
            {
              dataIndex: 'frequency',
              key: 'frequency',
              align: 'left',
              width: 150,
              slots: { title: 'customFrequencyTitle', customRender: 'frequency' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
          ],
        },
      ],
      view: [
        {
          title: 'Campaign',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          slots: { title: 'customNameTitle', customRender: 'name' },
        },
        {
          dataIndex: 'mode',
          key: 'mode',
          align: 'center',
          width: 300,
          slots: { title: 'customModeTitle', customRender: 'mode' },
          children: [
            {
              dataIndex: 'videoView',
              key: 'videoView',
              align: 'left',
              width: 150,
              slots: { title: 'customVideoViewTitle', customRender: 'videoView' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
            {
              dataIndex: 'pageView',
              key: 'pageView',
              align: 'left',
              width: 150,
              slots: { title: 'customPageViewTitle', customRender: 'pageView' },
              customRender: ({ text, record }) => {
                if (record && record.highestValue) {
                  return {
                    children: convertValue(text.value),
                    props: {
                      style: { background: '#def7ef' },
                    },
                  };
                }
                return {
                  children: convertValue(text.value),
                };
              },
            },
          ],
        },
      ],
    });
    const metricColumnsMobileByMode = reactive({
      engagement: [
        {
          title: 'Campaign',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          slots: { title: 'customNameTitle', customRender: 'name' },
        },
        {
          dataIndex: 'engagement',
          key: 'engagement',
          align: 'left',
          slots: { title: 'customTotalEngagementTitle', customRender: 'engagement' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
        {
          dataIndex: 'reactions',
          key: 'reactions',
          align: 'left',
          slots: { title: 'customReactionTitle', customRender: 'reactions' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
        {
          dataIndex: 'comment',
          key: 'comment',
          align: 'left',
          slots: { title: 'customCommentTitle', customRender: 'comment' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
        {
          dataIndex: 'share',
          key: 'share',
          align: 'left',
          slots: { title: 'customShareTitle', customRender: 'share' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
        {
          dataIndex: 'engagementPerMention',
          key: 'engagementPerMention',
          align: 'left',
          slots: { title: 'customModeDataTitle', customRender: 'mode' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
      ],
      mention: [
        {
          title: 'Campaign',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          slots: { title: 'customNameTitle', customRender: 'name' },
        },
        {
          dataIndex: 'mentioned',
          key: 'mentioned',
          align: 'left',
          slots: { title: 'customTotalMetionTitle', customRender: 'mentioned' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
        {
          dataIndex: 'frequency',
          key: 'frequency',
          align: 'left',
          slots: { title: 'customFrequencyTitle', customRender: 'frequency' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
      ],
      view: [
        {
          title: 'Campaign',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          slots: { title: 'customNameTitle', customRender: 'name' },
        },
        {
          dataIndex: 'videoView',
          key: 'videoView',
          align: 'left',
          slots: { title: 'customVideoViewTitle', customRender: 'videoView' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
        {
          dataIndex: 'pageView',
          key: 'pageView',
          align: 'left',
          slots: { title: 'customPageViewTitle', customRender: 'pageView' },
          customRender: ({ text, record }) => {
            if (record && record.highestValue) {
              return {
                children: convertValue(text.value),
                props: {
                  style: { background: '#def7ef' },
                },
              };
            }
            return {
              children: convertValue(text.value),
            };
          },
        },
      ],
    });
    const metricColumns = ref([]);
    const metricMobileColumns = ref([]);
    const metricData = ref([]);
    const metricDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });

    const convertValue = (value) => {
      if (value) {
        return helper.numeral(value);
      }
      return 0;
    };

    const init = async (fv) => {
      loading.value = true;
      if (fv) {
        const result = await api.getCategoryCompareStat(fv, campaignLevel.value).catch(() => {
          console.error('Comparison By Metric api call error', result);
        });
        if (result.status && result.message && result.message.stat) {
          const stat = result.message.stat;

          const countList = [];
          const engageList = [];
          const viewList = [];

          let objEngage;
          let objMention;
          let objView;

          for (let key of stat) {
            objEngage = {
              name: key.category,
              engagement: {
                value: convertValue(key.engagement),
                highestValue: key.highEngagement,
              },
              reactions: {
                value: convertValue(key.reactions),
                highestValue: key.highReaction,
              },
              comment: {
                value: convertValue(key.comment),
                highestValue: key.highComment,
              },
              share: {
                value: convertValue(key.share),
                highestValue: key.highShare,
              },
              engagementPerMention: {
                value: convertValue(key.engagementPerMention),
                highestValue: key.highEngagementPerMentioned,
              },
            };
            objMention = {
              name: key.category,
              mentioned: {
                value: convertValue(key.mentioned),
                highestValue: key.highMentioned,
              },
              frequency: {
                value: convertValue(key.frequency),
                highestValue: key.highFrequency,
              },
            };
            objView = {
              name: key.category,
              videoView: {
                value: convertValue(key.videoView),
                highestValue: key.highVideoView,
              },
              pageView: {
                value: convertValue(key.pageView),
                highestValue: key.highPageView,
              },
            };
            engageList.push(objEngage);
            countList.push(objMention);
            viewList.push(objView);
          }

          metricDataByMode.engagement = engageList;
          metricDataByMode.mention = countList;
          metricDataByMode.view = viewList;

          metricData.value = metricDataByMode[mode.value];

          checkMobileScreen();
        }
      }
      loading.value = false;
    };

    const checkMobileScreen = () => {
      if (isMobileScreen.value) {
        metricMobileColumns.value = metricColumnsMobileByMode[mode.value];
      } else {
        metricColumns.value = metricColumnsByMode[mode.value];
      }
    };

    const getColor = (catKey, index) => {
      return helper.getColorByCategory(campaignLevel.value, catKey, index);
    };

    watch(
      () => filterResult.value,
      () => {
        init(filterResult.value);
      }
    );

    watch(
      () => mode.value,
      () => {
        checkMobileScreen();
        metricData.value = metricDataByMode[mode.value];
      }
    );

    watch(
      () => isMobileScreen.value,
      () => {
        checkMobileScreen();
      }
    );

    onMounted(() => {
      init(filterResult.value);
    });

    return {
      loading,
      metricColumns,
      isMobileScreen,
      getColor,
      metricData,
      metricMobileColumns,
      metricDataByMode,
    };
  },
};
</script>

<style lang="scss" scoped>
.channel-table-wrapper {
  padding: 0;
  .loading-wrapper {
    max-height: 525px;
    display: flex;
    align-items: center;
    padding: 25px;
  }
  .channel-table {
    .campaign-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #272b41;
    }
    .mode-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #272b41;
      text-transform: capitalize;
    }

    .category-title {
      font-weight: 700;
      font-size: 16px;
      color: #272b41;
    }

    .comparison-wrapper {
      .category-text {
        padding-left: 8px;
        color: #5a5f7d;
        text-transform: capitalize;
        text-overflow: ellipsis;
      }
    }

    .badge-wrapper {
      min-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      transform: translateX(0px);
      text-overflow: ellipsis;
      .category-text {
        padding-left: 8px;
        color: #5a5f7d;
        overflow: hidden;
        text-transform: capitalize;
        text-overflow: ellipsis;
      }
    }
    @media screen and (max-width: 600px) {
      .badge-wrapper {
        min-width: 100px;
      }
    }

    .source-value {
      &.highlight-color {
        background: #fff;
        // background: #def7ef;
      }
    }

    .category-data {
      color: #5a5f7d;
    }
  }
}
</style>
<style scoped>
:deep(.channel-table .ant-table-thead > tr > th) {
  background: #fff !important;
  border: 1px solid #f0f0f0 !important;
  border-right: none !important;
  border-bottom: none !important;
  text-align: center;
}

:deep(.channel-table .ant-table-thead > tr > th:first-child) {
  border-bottom: 1px solid #f0f0f0 !important;
  width: 200px;
}

:deep(.channel-table .ant-table-thead > tr:first-child > th:last-child) {
  background: #fafafa !important;
}

:deep(.channel-table .ant-table-thead > tr > th:last-child) {
  border-right: 1px solid #f0f0f0 !important;
}

:deep(.channel-table .ant-table-thead > tr:last-child > th) {
  border-bottom: 1px solid #f0f0f0 !important;
}

:deep(.ant-table-scroll table) {
  scroll-behavior: smooth;
  border-top: none;
}

:deep(.channel-table .ant-table tbody > tr > td) {
  border-left: 1px solid #f0f0f0 !important;
  border-right: none !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

:deep(.channel-table .ant-table tbody > tr > td:first-child) {
  width: 200px;
}

:deep(.channel-table .ant-table tbody > tr > td:last-child) {
  border-right: 1px solid #f0f0f0 !important;
}

:deep(.ant-table-wrapper) {
  clear: both;
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  :deep(.channel-table .ant-table-thead > tr:first-child > th:last-child) {
    background: #fff !important;
  }
}
</style>
